import StateStorage from '../state-storage'
import { request } from './index'
import { HTTP_METHODS } from './const'

class State {
  static async getState(options) {
    const language = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const regionIDFilter = options?.params?.['filter[id_region]']
    const cacheKey = `pomeloState-${shop}-${language}-${options?.filter || 0}${
      regionIDFilter ? `-${regionIDFilter}` : ''
    }`

    const cached = StateStorage.getLocalState(cacheKey)

    if (cached) {
      return Promise.resolve(cached)
    }

    const res = await request({
      url: `v6/static/countries/${options?.filter}/states`,
      method: HTTP_METHODS.get,
      params: options?.params,
    })

    StateStorage.saveLocalState(cacheKey, res, 600)

    return res
  }
}

export default State
