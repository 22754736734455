import Duck from 'extensible-duck'
import { REFERRAL_HISTORY_TAB } from './const'

const initialState = {
  selectedTab: REFERRAL_HISTORY_TAB.IN_PROGRESS,
  tabOrderList: [],
  orderCount: {},
  inprogressOrderList: [],
  successOrderList: [],
  incompleteOrderList: [],
}

const types = [
  'UPDATE_SELECTED_TAB',
  'UPDATE_REFERRAL_ORDER',
  'RESET_REFERRAL_ORDER',
]

const selectedOrderList = (state, tab) => {
  switch (tab) {
    case REFERRAL_HISTORY_TAB.SUCCESS:
      return state.successOrderList
    case REFERRAL_HISTORY_TAB.IN_COMPLETE:
      return state.incompleteOrderList
    case REFERRAL_HISTORY_TAB.IN_PROGRESS:
    default:
      return state.inprogressOrderList
  }
}
export default new Duck({
  namespace: 'content',
  store: 'referralHistory',
  types,
  initialState,
  reducer: (state, action, reducerDuck) => {
    switch (action.type) {
      case reducerDuck.types.UPDATE_SELECTED_TAB:
        return {
          ...state,
          selectedTab: action.tab,
          tabOrderList: selectedOrderList(state, action.tab),
        }
      case reducerDuck.types.UPDATE_REFERRAL_ORDER:
        return {
          ...state,
          orderCount: action.orderCount,
          tabOrderList: action.inprogressOrderList,
          inprogressOrderList: action.inprogressOrderList,
          successOrderList: action.successOrderList,
          incompleteOrderList: action.incompleteOrderList,
        }
      case reducerDuck.types.RESET_REFERRAL_ORDER:
        return {
          ...state,
          tabOrderList: [],
          orderCount: {},
          inprogressOrderList: [],
          successOrderList: [],
          incompleteOrderList: [],
        }
      default:
        return state
    }
  },
  creators: (creatorDuck) => ({
    updateSelectedTab: (tab) => ({
      type: creatorDuck.types.UPDATE_SELECTED_TAB,
      tab,
    }),
    updateReferralOrder: (
      orderCount,
      inprogressOrderList,
      successOrderList,
      incompleteOrderList,
    ) => ({
      type: creatorDuck.types.UPDATE_REFERRAL_ORDER,
      orderCount,
      inprogressOrderList,
      successOrderList,
      incompleteOrderList,
    }),
    resetReferralOrder: () => ({
      type: creatorDuck.types.RESET_REFERRAL_ORDER,
    }),
  }),
})

export { initialState as referralHistoryInitialState }
