import Duck from 'extensible-duck'

const initialState = {
  toggled: false,
}

export default new Duck({
  namespace: 'content',
  store: 'pomeloBrandFilter',
  types: ['TOGGLE', 'RESET'],
  initialState,
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.TOGGLE:
        return { toggled: !state.toggled }
      case duck.types.RESET:
        return { toggled: false }
      default:
        return state
    }
  },
  creators: (duck) => ({
    toggle: (toggled) => ({ type: duck.types.TOGGLE, toggled }),
    reset: () => ({ type: duck.types.RESET }),
  }),
})
