import { configureStore, combineReducers } from '@reduxjs/toolkit'
import thunkMiddleware from 'redux-thunk'
import countryDuck from '../components/addresses/countries/duck'
import addressDuck from '../components/addresses/duck'
import stateDuck from '../components/addresses/state/duck'
import appBannerDuck from '../components/alert/alert-app/duck'
import appCreditsDuck from '../components/alert/alert-credits/duck'
import authDuck from '../components/auth/duck'
import carrierDuck from '../components/carrier/duck'
import cartlistDuck from '../components/cart/cart-items/duck'
import cartRecommendationsDuck from '../components/cart/cart-recommendations/duck'
import voucherDuck from '../components/cart/cart-voucher-code/duck'
import cartDuck from '../components/cart/duck'
import freeGiftDuck from '../components/cart/free-gift/duck'
import categoryDuck from '../components/category/category-item/duck'
import bankTransferDetailDuck from '../components/checkout/bank-transfer-detail/duck'
import checkoutDuck from '../components/checkout/duck'
import checkoutPaymentMethodDuck from '../components/checkout/payment-options/duck'
import checkoutSummaryDuck from '../components/checkout/summary/duck'
import editorialSlidesDuck from '../components/editorial/editorial-slides/duck'
import filterDuck from '../components/filter/filter-options/duck'
import pomeloBrandFilterDuck from '../components/filter/pomelo-filter/duck'
import linksDuck from '../components/links/duck'
import lookbookDuck from '../components/lookbook/lookbook-item/duck'
import googleMapDuck from '../components/maps-google/duck'
import modalDuck from '../components/modal/duck'
import ordersDuck from '../components/order-list/duck'
import orderDuck from '../components/order/duck'
import productDuck from '../components/product/product-item/duck'
import productListDuck from '../components/product/product-list/duck'
import returnsDuck from '../components/return-list/duck'
import returnDuck from '../components/return/duck'
import returnItemDuck from '../components/return/return-items/duck'
import returnLocationsDuck from '../components/return/return-locations/duck'
import returnPageDuck from '../components/returns-page/duck'
import referralHistoryDuck from '../components/referral/landing/landing-desktop/history/duck'
import refundMethodsDuck from '../components/returns-page/refund-methods/duck'
import searchModalDuck from '../components/search/search-section/duck'
import sortDuck from '../components/sort/sort-options/duck'
import ttbHomePaymentMethodDuck from '../components/ttb-home/duck'
import userInfo from '../components/user/account-details/duck'
import creditCards from '../components/user/credit-cards/duck'
import filterVisibilityDuck from '../components/visibility/duck'
import wishlistDuck from '../components/wishlist/duck'
import wishlistDataDuck from '../components/wishlist/wishlist-products/duck'
import cartBadgeDuck from '../lib/api/cart-badge'
import deviceDuck from '../lib/device/duck'
import i18nDuck from '../lib/i18n/duck'
import kcgFlagDuck from '../components/kcg/duck'
import userOrderPageDuck from '../pages/user/order/duck'
import reviewOrderPageDuck from '../pages/review/order/duck'
import orderStatusesFilterDuck from '../components/my-account/my-account-order-statuses/duck'

const dev = process.env.NODE_ENV !== 'production'
const app = combineReducers({
  [addressDuck.store]: addressDuck.reducer,
  [appBannerDuck.store]: appBannerDuck.reducer,
  [appCreditsDuck.store]: appCreditsDuck.reducer,
  [authDuck.store]: authDuck.reducer,
  [bankTransferDetailDuck.store]: bankTransferDetailDuck.reducer,
  [carrierDuck.store]: carrierDuck.reducer,
  [cartDuck.store]: cartDuck.reducer,
  [cartlistDuck.store]: cartlistDuck.reducer,
  [cartBadgeDuck.store]: cartBadgeDuck.reducer,
  [cartRecommendationsDuck.store]: cartRecommendationsDuck.reducer,
  [categoryDuck.store]: categoryDuck.reducer,
  [checkoutDuck.store]: checkoutDuck.reducer,
  [checkoutPaymentMethodDuck.store]: checkoutPaymentMethodDuck.reducer,
  [checkoutSummaryDuck.store]: checkoutSummaryDuck.reducer,
  [countryDuck.store]: countryDuck.reducer,
  [creditCards.store]: creditCards.reducer,
  [deviceDuck.store]: deviceDuck.reducer,
  [editorialSlidesDuck.store]: editorialSlidesDuck.reducer,
  [filterDuck.store]: filterDuck.reducer,
  [pomeloBrandFilterDuck.store]: pomeloBrandFilterDuck.reducer,
  [freeGiftDuck.store]: freeGiftDuck.reducer,
  [googleMapDuck.store]: googleMapDuck.reducer,
  [i18nDuck.store]: i18nDuck.reducer,
  [kcgFlagDuck.store]: kcgFlagDuck.reducer,
  [linksDuck.store]: linksDuck.reducer,
  [lookbookDuck.store]: lookbookDuck.reducer,
  [modalDuck.store]: modalDuck.reducer,
  [orderDuck.store]: orderDuck.reducer,
  [ordersDuck.store]: ordersDuck.reducer,
  [orderStatusesFilterDuck.store]: orderStatusesFilterDuck.reducer,
  [productDuck.store]: productDuck.reducer,
  [productListDuck.store]: productListDuck.reducer,
  [stateDuck.store]: stateDuck.reducer,
  [referralHistoryDuck.store]: referralHistoryDuck.reducer,
  [refundMethodsDuck.store]: refundMethodsDuck.reducer,
  [returnDuck.store]: returnDuck.reducer,
  [returnItemDuck.store]: returnItemDuck.reducer,
  [returnLocationsDuck.store]: returnLocationsDuck.reducer,
  [returnPageDuck.store]: returnPageDuck.reducer,
  [returnsDuck.store]: returnsDuck.reducer,
  [reviewOrderPageDuck.store]: reviewOrderPageDuck.reducer,
  [searchModalDuck.store]: searchModalDuck.reducer,
  [sortDuck.store]: sortDuck.reducer,
  [userInfo.store]: userInfo.reducer,
  [userOrderPageDuck.store]: userOrderPageDuck.reducer,
  [ttbHomePaymentMethodDuck.store]: ttbHomePaymentMethodDuck.reducer,
  [filterVisibilityDuck.store]: filterVisibilityDuck.reducer,
  [voucherDuck.store]: voucherDuck.reducer,
  [wishlistDuck.store]: wishlistDuck.reducer,
  [wishlistDataDuck.store]: wishlistDataDuck.reducer,
})

const initializeStore = (initialState = {}) => {
  const middlewares = [thunkMiddleware]

  const store = configureStore({
    reducer: app,
    preloadedState: { ...initialState },
    devTools: dev,
    middleware: middlewares,
  })

  return store
}

export default initializeStore
