import Duck from 'extensible-duck'

export default new Duck({
  namespace: 'content',
  store: 'sort',
  types: ['CLEAN', 'UPDATE'],
  initialState: { by: '' },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE:
        return { ...state, by: action.by }
      case duck.types.CLEAN:
        return { ...state, by: '' }
      default:
        return state
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    update: (by) => ({ type: duck.types.UPDATE, by }),
    clean: () => ({ type: duck.types.CLEAN }),
  }),
})
