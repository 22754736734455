export const checkIsInvalidReviewData = (reviewData) =>
  Boolean(reviewData.title) && !reviewData.description

export const checkIsValidRatingData = (ratingData) =>
  Boolean(ratingData.rating_true_to_size) &&
  Boolean(ratingData.rating_product_as_display) &&
  Boolean(ratingData.rating_design_and_style) &&
  Boolean(ratingData.rating_product_quality)

export const checkIsHaveRatingData = (ratingData) =>
  Boolean(ratingData.rating_true_to_size) ||
  Boolean(ratingData.rating_product_as_display) ||
  Boolean(ratingData.rating_design_and_style) ||
  Boolean(ratingData.rating_product_quality)

export const checkIsAllowSubmitProductOrderReview = (reviewOrderPageData) => {
  const { imageData, reviewData, ratingData } = reviewOrderPageData
  const isHaveReviewImage = imageData.images?.length > 0
  const isHaveReviewDescription = Boolean(reviewData.description)
  const isHaveRatingData = checkIsHaveRatingData(ratingData)

  const isInvalidReviewData = checkIsInvalidReviewData(reviewData)
  const isValidRatingData = checkIsValidRatingData(ratingData)

  const isNotAllowSubmitReview =
    isInvalidReviewData || (isHaveRatingData && !isValidRatingData)

  const isAllowSubmitReview =
    (isHaveReviewImage || isHaveReviewDescription || isHaveRatingData) &&
    !isNotAllowSubmitReview

  return isAllowSubmitReview
}
