import Duck from 'extensible-duck'

const duck = new Duck({
  namespace: 'kcg',
  store: 'kcg',
  types: ['KCG_UPDATE'],
  initialState: {
    isCreditEnable: false,
    isCashbackEnable: false,
  },
  reducer: (state, action) => {
    switch (action.type) {
      case duck.types.KCG_UPDATE:
        return { ...state, ...action.payload }
      default:
        return state
    }
  },
})

export default duck
