import Duck from 'extensible-duck'
import { checkIsAllowSubmitProductOrderReview } from 'components/review/order/utilities/ReviewProductOrderUtilities'

const initialState = {
  id_product_review: null,
  isAllowSubmitReview: false,
  imageData: {
    images: [],
    // toDeleteImageIds and toUploadImageFiles is to specifically track the images that need to be add or remove from the API
    toDeleteImageIds: [],
    toUploadImageFiles: [],
    caption: '',
  },
  reviewData: { title: '', description: '' },
  ratingData: {
    rating_true_to_size: '',
    rating_product_as_display: null,
    rating_design_and_style: null,
    rating_product_quality: null,
  },
  bioData: {
    user_height: '',
    user_weight: '',
  },
  review_cashback_config: {
    gallery: '',
    rating: '',
    total: '',
  },
}

const types = [
  'UPDATE_SUBMIT_VALIDATION',
  'UPDATE_IMAGE_DATA',
  'UPDATE_REVIEW_DATA',
  'UPDATE_RATING_DATA',
  'UPDATE_BIO_DATA',
  'UPDATE_PRODUCT_REVIEW_DATA',
]

export default new Duck({
  namespace: 'content',
  store: 'reviewOrderPage',
  types,
  initialState,
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.UPDATE_SUBMIT_VALIDATION:
        return { ...state, isAllowSubmitReview: action.isAllowSubmitReview }
      case rDuck.types.UPDATE_IMAGE_DATA: {
        const newState = {
          ...state,
          imageData: { ...state.imageData, ...action.imageData },
        }
        return {
          ...newState,
          isAllowSubmitReview: checkIsAllowSubmitProductOrderReview(newState),
        }
      }
      case rDuck.types.UPDATE_REVIEW_DATA: {
        const newState = {
          ...state,
          reviewData: { ...state.reviewData, ...action.reviewData },
        }
        return {
          ...newState,
          isAllowSubmitReview: checkIsAllowSubmitProductOrderReview(newState),
        }
      }
      case rDuck.types.UPDATE_RATING_DATA: {
        const newState = {
          ...state,
          ratingData: { ...state.ratingData, ...action.ratingData },
        }
        return {
          ...newState,
          isAllowSubmitReview: checkIsAllowSubmitProductOrderReview(newState),
        }
      }
      case rDuck.types.UPDATE_BIO_DATA: {
        const newState = {
          ...state,
          bioData: { ...state.bioData, ...action.bioData },
        }
        return {
          ...newState,
          isAllowSubmitReview: checkIsAllowSubmitProductOrderReview(newState),
        }
      }
      case rDuck.types.UPDATE_PRODUCT_REVIEW_DATA:
        return {
          ...state,
          ...action.productReviewData,
        }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    updateSubmitValidation: (isAllowSubmitReview) => ({
      type: cDuck.types.UPDATE_SUBMIT_VALIDATION,
      isAllowSubmitReview,
    }),
    updateImageData: (imageData) => ({
      type: cDuck.types.UPDATE_IMAGE_DATA,
      imageData,
    }),
    updateReviewData: (reviewData) => ({
      type: cDuck.types.UPDATE_REVIEW_DATA,
      reviewData,
    }),
    updateRatingData: (ratingData) => ({
      type: cDuck.types.UPDATE_RATING_DATA,
      ratingData,
    }),
    updateBioData: (bioData) => ({
      type: cDuck.types.UPDATE_BIO_DATA,
      bioData,
    }),
    updateProductReviewData: (productReviewData) => ({
      type: cDuck.types.UPDATE_PRODUCT_REVIEW_DATA,
      productReviewData,
    }),
  }),
})

export { initialState as reviewOrderPageInitState }
