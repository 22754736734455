import createDuck from 'lib/ducks/remoteDuck'
import Product from 'lib/api/product'

// Currently this duck is only used for storing the PDP data.
// Fetch function is not being used.
export default createDuck({
  namespace: 'content',
  store: 'product',
  fetchCallback: (options) => Product.getProduct(options),
})
