import { request } from 'lib/api'
import { getFlagValue } from 'lib/flagsmith'
import StateStorage from '../state-storage'
import { HTTP_METHODS } from './const'

class Navigation {
  static getNavigationUrl = async () => {
    const NavigationUrl = await getFlagValue(
      'AppCategoryNavigationNewEndpoint_20221017',
      false,
    )
      .then((flag) => (flag ? `v7/navigations/categories` : `v6/navigations`))
      .catch(() => `v6/navigations`)

    return NavigationUrl
  }

  static async getLinks(isMobile = false) {
    const lang = StateStorage.getLanguage()
    const shop = StateStorage.getShop()
    const keyName = `pomelo${isMobile ? 'Mobile' : 'Desktop'}Nav`
    const localStateKey = `${keyName}-${shop}-${lang}`
    const cached = StateStorage.getLocalState(localStateKey)

    if (cached) {
      return Promise.resolve(cached)
    }
    const url = await Navigation.getNavigationUrl()

    const res = await request({
      url,
      method: HTTP_METHODS.get,
      params: { bar_type: isMobile ? 'mobile' : 'web_top' },
    })

    StateStorage.saveLocalState(localStateKey, res, 60)

    return res
  }
}

export default Navigation
