import Duck from 'extensible-duck'
import StateStorage from '../state-storage'

export default function createDuck({ store }) {
  return new Duck({
    namespace: 'banner',
    store,

    types: ['ENABLE', 'DISABLE', 'SET_DATA'],

    initialState: {
      active: true,
      data: null,
    },

    reducer: (state, action, duck) => {
      switch (action.type) {
        case duck.types.ENABLE:
          return { ...state, active: true }
        case duck.types.DISABLE:
          StateStorage.saveBannerStatus(duck.store, false)
          return { ...state, active: false }
        case duck.types.SET_DATA:
          return { ...state, data: action.data }
        default:
          return state
      }
    },

    creators: (duck) => ({
      enable: () => ({ type: duck.types.ENABLE }),
      setData: (data) => ({ type: duck.types.SET_DATA, data }),
      disable: () => ({ type: duck.types.DISABLE }),
    }),
  })
}
