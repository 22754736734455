import 'setimmediate'
import withRedux from 'next-redux-wrapper'
import App, { Container } from 'next/app'
import Router from 'next/router'
import { setCookie } from 'nookies'
import NProgress from 'nprogress'
import React from 'react'
import TagManager from 'react-gtm-module'
import { Provider } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import { v4 as uuid } from 'uuid'
import env from 'config/env'
import Preloader from 'components/preloader'
import StateStorage from 'lib/state-storage'
import {
  SEGMENT_STORAGE_KEY_CATEGORY,
  SEGMENT_STORAGE_KEY_IS_REFRESHED,
  SEGMENT_STORAGE_KEY_LAST_URL,
  SEGMENT_STORAGE_KEY_NAVIGATION,
  SEGMENT_STORAGE_KEY_PRODUCT,
  SEGMENT_STORAGE_KEY_SSR,
} from 'lib/segment/const'
import { fetchBranchCrossPlatformId } from 'lib/segment'
import { DESKTOP, PHONE } from 'constants/devices'
import {
  CSR_NAV_TRACK,
  DEVICE_COOKIES,
  DISABLE_NAV_PROGRESS,
  POMELO_CARES_PAYPAL_WEBVIEW,
  SEGMENT_ANONYMOUS_ID_COOKIES,
  USER_UID_COOKIES,
  WEB_VIEW_X_PLATFORM,
} from 'constants/cookies'
import update3rdPartyConsent from 'lib/utils/pdpa-consent'
import { identifyLoggedInUser } from 'lib/auth'
import initFlagSmith from 'lib/flagsmith'
import { remoteConfig } from 'lib/firebase'
import kcgFlagDuck from 'components/kcg/duck'
import initializeStore from '../store'

class CustomApp extends App {
  constructor(props) {
    super(props)

    this.state = {
      isKCGFetching: true,
    }
  }

  static async getInitialProps({ Component, ctx }) {
    if (ctx.req) {
      const { headers, params } = ctx.req
      const { zone, lang } = params
      StateStorage.initializeCookies(headers.cookie, zone, lang)
    }

    let pageProps = {}
    const user_uid = CustomApp.serverSideFetchCookie(USER_UID_COOKIES)
    const segment_anonymous_id = CustomApp.serverSideFetchCookie(
      SEGMENT_ANONYMOUS_ID_COOKIES,
    )
    let device

    if (!process.browser) {
      CustomApp.setDeviceCookies(ctx)
      device = StateStorage.getCookie(DEVICE_COOKIES)
    }

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({
        ...ctx,
        device,
        segment_anonymous_id,
        user_uid,
      })
    }

    const user = StateStorage.getAuthUser()
    if (user) {
      ctx.store.dispatch({ type: 'user/auth/SET_USER', user })
    }

    return {
      device,
      pageProps,
      segment_anonymous_id,
      user_uid,
      ...(!process.browser && { xPlatform: ctx.req.headers['x-platform'] }),
    }
  }

  static serverSideFetchCookie(cookieName) {
    let cookieData

    cookieData = StateStorage.getCookie(cookieName)

    if (cookieData === 'null' || !cookieData) {
      cookieData = uuid()
    }
    return cookieData
  }

  static setDeviceCookies(ctx) {
    /**
     * we treat iPad as an Desktop
     * in future, maybe we treat desktop,phone,tablet differently
     * in case you want to change, use cts.res.locals.device_type
     */
    const { device_type } = ctx.res.locals
    return device_type === PHONE
      ? setCookie(ctx, DEVICE_COOKIES, PHONE, { path: '/' })
      : setCookie(ctx, DEVICE_COOKIES, DESKTOP, { path: '/' })
  }

  isSessionChecked = false

  // Use istanbul ignore for now
  // reason: Do it later
  /* istanbul ignore next */
  async fetchKCGConfig() {
    const { store } = this.props
    const shopId = store.getState().internationalization.shop

    try {
      await remoteConfig.fetchAndActivate()
      const cashbackEnabledShopIDs = JSON.parse(
        // eslint-disable-next-line no-underscore-dangle
        remoteConfig.getValue('loyalty_enabled')._value,
      )
      const creditEnabledShopIDs = JSON.parse(
        // eslint-disable-next-line no-underscore-dangle
        remoteConfig.getValue('PMLCreditVisibility_20231101')._value,
      )

      store.dispatch({
        type: kcgFlagDuck.types.KCG_UPDATE,
        payload: {
          isCreditEnable: creditEnabledShopIDs.includes(shopId),
          isCashbackEnable: cashbackEnabledShopIDs.includes(shopId),
        },
      })
    } finally {
      this.setState({ isKCGFetching: false })
    }
  }

  componentDidMount() {
    this.initializeApp()
    identifyLoggedInUser()
    smoothscroll.polyfill()
  }

  initializeApp() {
    if (
      env.NODE_ENV !== 'test' &&
      env.GOOGLE_TAG_MANAGER_ID.trim().length > 0
    ) {
      TagManager.initialize({ gtmId: env.GOOGLE_TAG_MANAGER_ID })
    }
    sessionStorage.setItem(SEGMENT_STORAGE_KEY_LAST_URL, window.location.href)
    this.clientSideCheckForUuid()
    this.clientSideCheckForAnonymousId()
    this.mountBodyClasses()
    this.mountRouteListener()
    fetchBranchCrossPlatformId()
    update3rdPartyConsent()
    initFlagSmith()

    this.fetchKCGConfig()
  }

  mountBodyClasses() {
    const bodyEl = document.body
    const queryParams = new URLSearchParams(window.location.search)
    const bodyClasses = []

    bodyEl.classList.remove('freezed')

    if (
      queryParams.has('webview') ||
      (document.referrer.startsWith('https://www.paypal.com') &&
        localStorage.getItem(POMELO_CARES_PAYPAL_WEBVIEW))
    ) {
      const { xPlatform } = this.props
      bodyClasses.push('app-webview')

      if (xPlatform) {
        localStorage.setItem(WEB_VIEW_X_PLATFORM, xPlatform)
      }
    }

    if (localStorage) {
      localStorage.removeItem(WEB_VIEW_X_PLATFORM)
      localStorage.removeItem(POMELO_CARES_PAYPAL_WEBVIEW)
    }

    if (queryParams.has('utm_source')) {
      bodyClasses.push('app-utm')
    }

    if (bodyClasses.length) {
      bodyEl.classList.add(...bodyClasses)
    }
  }

  // Use this solution to check the session, since sessionStorage persist when the page is refreshed.
  clientSideCheckSession() {
    if (!this.isSessionChecked && typeof window !== 'undefined') {
      this.isSessionChecked = true
      const isSSRTracked = sessionStorage.getItem(SEGMENT_STORAGE_KEY_SSR)
      const lastUrl = sessionStorage.getItem(SEGMENT_STORAGE_KEY_LAST_URL)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_SSR)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_LAST_URL)
      sessionStorage.removeItem(CSR_NAV_TRACK)

      // Prevent incorrect leftover tracking data
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_NAVIGATION.wishlist.id)
      sessionStorage.removeItem(
        SEGMENT_STORAGE_KEY_NAVIGATION.wishlist.position,
      )
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_NAVIGATION.wishlist.type)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_CATEGORY.id)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_CATEGORY.position)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_CATEGORY.subPos)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_CATEGORY.type)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_PRODUCT.id)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_PRODUCT.position)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_PRODUCT.subPos)
      sessionStorage.removeItem(SEGMENT_STORAGE_KEY_PRODUCT.type)
      // End section

      // This is a refresh
      if (isSSRTracked && (!lastUrl || lastUrl === window.location.href)) {
        sessionStorage.setItem(SEGMENT_STORAGE_KEY_IS_REFRESHED, 'true')
      }
    }
  }

  // We set the user_uid cookie here only. The uid got generated from the server-side.
  clientSideCheckForUuid() {
    const { user_uid } = this.props

    if (!StateStorage.getCookie(USER_UID_COOKIES)) {
      StateStorage.setCookie(USER_UID_COOKIES, user_uid)
    }
  }

  clientSideCheckForAnonymousId() {
    const { segment_anonymous_id } = this.props

    if (!StateStorage.getCookie(SEGMENT_ANONYMOUS_ID_COOKIES)) {
      StateStorage.setCookie(SEGMENT_ANONYMOUS_ID_COOKIES, segment_anonymous_id)
    }
  }

  mountRouteListener() {
    let csrNavTrack = 0

    Router.onRouteChangeStart = () => {
      if (!sessionStorage.getItem(DISABLE_NAV_PROGRESS)) {
        NProgress.start()
      } else {
        setImmediate(() => sessionStorage.removeItem(DISABLE_NAV_PROGRESS))
      }
    }

    Router.onRouteChangeComplete = () => {
      csrNavTrack += 1
      sessionStorage.setItem(CSR_NAV_TRACK, csrNavTrack)
      sessionStorage.setItem(SEGMENT_STORAGE_KEY_LAST_URL, window.location.href)
      NProgress.done()
      if (window.branch) {
        window.branch.closeJourney()
      }
    }

    Router.onRouteChangeError = () => {
      NProgress.done()
    }
  }

  componentDidCatch(error, errorInfo) {
    super.componentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, store } = this.props
    this.clientSideCheckSession()
    this.clientSideCheckForUuid()

    return (
      <Container>
        <React.Fragment>
          <Preloader isKCGFetching={this.state.isKCGFetching} />
          <Provider store={store}>
            <Component {...pageProps} />
          </Provider>
        </React.Fragment>
      </Container>
    )
  }
}

export { CustomApp }

export default withRedux(initializeStore)(CustomApp)
