import Duck from 'extensible-duck'

const initialState = {
  buttonDisabled: false,
  data3Ds: null,
  error: false,
  errorMessage: null,
  isThShop: false,
  isIndoShop: false,
  loading: false,
  show3dsModal: false,
  voucherErrorMessage: null,
}

const types = [
  'RESET',
  'UPDATE_3DS_MODAL',
  'UPDATE_ERROR_DATA',
  'UPDATE_LOADING',
  'UPDATE_MULTIPLE_STATE',
  'UPDATE_VOUCHER_ERROR_MSG',
]

export default new Duck({
  namespace: 'checkout',
  store: 'checkoutSummary',
  types,
  initialState,
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.RESET:
        return initialState
      case rDuck.types.UPDATE_3DS_MODAL:
        return { ...state, show3dsModal: action.isOpen }
      case rDuck.types.UPDATE_ERROR_DATA:
        return {
          ...state,
          error: action.status,
          errorMessage: action.msg,
          ...(![undefined, null].includes(action.loading) && {
            loading: action.loading,
          }),
        }
      case rDuck.types.UPDATE_LOADING:
        return { ...state, loading: action.loading }
      case rDuck.types.UPDATE_MULTIPLE_STATE:
        return { ...state, ...action.data }
      case rDuck.types.UPDATE_VOUCHER_ERROR_MSG:
        return { ...state, voucherErrorMessage: action.msg }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    reset: () => ({ type: cDuck.types.RESET }),
    update3DSModal: (isOpen) => ({
      type: cDuck.types.UPDATE_3DS_MODAL,
      isOpen,
    }),
    updateErrorData: (status, msg, loading) => ({
      type: cDuck.types.UPDATE_ERROR_DATA,
      status,
      msg,
      loading,
    }),
    updateLoading: (loading) => ({
      type: cDuck.types.UPDATE_LOADING,
      loading,
    }),
    updateMultipleState: (data) => ({
      type: cDuck.types.UPDATE_MULTIPLE_STATE,
      data,
    }),
    updateVoucherErrorMsg: (msg) => ({
      type: cDuck.types.UPDATE_VOUCHER_ERROR_MSG,
      msg,
    }),
  }),
})

export { initialState as checkoutSummaryInitState }
