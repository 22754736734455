import { request } from './index'
import { HTTP_METHODS } from './const'

class FreeGift {
  static handleOptions(options) {
    switch (options.type) {
      default:
        return FreeGift.getFreeGift(options)
    }
  }

  static getFreeGift() {
    return request({
      url: 'v6/carts/current/free-gifts',
      method: HTTP_METHODS.get,
    })
  }
}

export default FreeGift
