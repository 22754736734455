import Duck from 'extensible-duck'
import { RETURN_STEPS } from 'pages/returns-page/const'

const returnsPageDuck = new Duck({
  namespace: 'returns',
  store: 'returnsPage',
  types: [
    'UPDATE_RETURNS_ITEMS',
    'DELETE_FROM_RETURNS_ITEMS',
    'UPDATE_REFUND_METHOD',
    'SET_ACTIVE_STEP',
    'SET_RETURN_ORDER_ID',
    'CLEAR_RETURN',
  ],
  initialState: {
    returnsItems: [],
    refundMethod: '',
    activeStep: RETURN_STEPS.SELECT_ITEMS,
    returnOrderID: '',
  },
  reducer: (state, action, duck) => {
    let newState = ''

    function checkIfAvailable(id) {
      let alreadyExist = false
      state.returnsItems.forEach((item) => {
        if (item.returnsItemID === id) {
          alreadyExist = true
        }
      })
      return alreadyExist
    }

    switch (action.type) {
      case duck.types.UPDATE_RETURNS_ITEMS:
        if (checkIfAvailable(action.returnsItems.returnsItemID)) {
          newState = state.returnsItems.filter(
            (item) => item.returnsItemID !== action.returnsItems.returnsItemID,
          )
          newState = newState.concat(action.returnsItems)
        } else {
          newState = state.returnsItems.concat(action.returnsItems)
        }
        return { ...state, returnsItems: newState }

      case duck.types.DELETE_FROM_RETURNS_ITEMS:
        newState = state.returnsItems.filter(
          (item) => item.returnsItemID !== action.returnsItems.returnsItemID,
        )
        return { ...state, returnsItems: newState }

      case duck.types.UPDATE_REFUND_METHOD:
        return { ...state, refundMethod: action.refundMethod }

      case duck.types.SET_ACTIVE_STEP:
        return { ...state, activeStep: action.activeStep }
      case duck.types.SET_RETURN_ORDER_ID:
        return { ...state, returnOrderID: action.returnOrderID }
      case duck.types.CLEAR_RETURN: {
        return {
          ...state,
          returnsItems: [],
          refundMethod: '',
          activeStep: RETURN_STEPS.SELECT_ITEMS,
          returnOrderID: '',
        }
      }
      default:
        return state
    }
  },
  creators: (duck) => ({
    updateReturnsItems: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_RETURNS_ITEMS,
        returnsItems: options.returnsItems,
      }),
    deleteFromReturnsItems: (options) => (dispatch) =>
      dispatch({
        type: duck.types.DELETE_FROM_RETURNS_ITEMS,
        returnsItems: options.returnsItems,
      }),
    updateRefundMethod: (options) => (dispatch) =>
      dispatch({
        type: duck.types.UPDATE_REFUND_METHOD,
        refundMethod: options.refundMethod,
      }),
    setActiveStep: (options) => (dispatch) =>
      dispatch({
        type: duck.types.SET_ACTIVE_STEP,
        activeStep: options.activeStep,
      }),
    setReturnOrderID: (options) => (dispatch) =>
      dispatch({
        type: duck.types.SET_RETURN_ORDER_ID,
        returnOrderID: options.returnOrderID,
      }),
    clearReturn: () => (dispatch) =>
      dispatch({
        type: duck.types.CLEAR_RETURN,
      }),
  }),
})

export default returnsPageDuck
