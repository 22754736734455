import Duck from 'extensible-duck'

const duck = new Duck({
  namespace: 'content',
  store: 'wishlistData',
  types: ['UPDATE'],
  initialState: {
    sortBy: 'recentlyAdded',
  },
  reducer: (state, action, rDuck) => {
    switch (action.type) {
      case rDuck.types.UPDATE:
        return { ...state, sortBy: action.sortBy }
      default:
        return state
    }
  },
  creators: (cDuck) => ({
    update: (options) => ({
      type: cDuck.types.UPDATE,
      sortBy: options.sortBy,
    }),
  }),
})

export default duck
