import { getFlagValue } from 'lib/flagsmith'
import { request } from '.'

class Category {
  static async get(options = {}) {
    const { categoryId: slugOrId } = options
    let categoryId = slugOrId

    if (Number.isNaN(parseInt(slugOrId, 10))) {
      const {
        device,
        isGuestMode,
        language: lang,
        segment_anonymous_id: segmentAnonymousID,
        shop,
        token,
        user_uid: userUID,
      } = options

      const res = await request({
        device,
        isGuestMode,
        lang,
        params: { slug: slugOrId },
        segmentAnonymousID,
        shop,
        token,
        url: 'v6/categories/search',
        userUID,
      })

      categoryId = res?.categories[0]?.id_category
    }

    return Category.getCategoryById({ ...options, categoryId })
  }

  static getCategoryById = async (options = {}) => {
    const {
      categoryId,
      device,
      isGuestMode,
      language: lang,
      segment_anonymous_id: segmentAnonymousID,
      shop,
      token,
      user_uid: userUID,
    } = options

    let url = await Category.getCategoryUrl(categoryId)

    if (options.filter) {
      const QP = Category.getQueryParams(options.filter)
      url += QP ? `?${QP}` : ''
    }

    return request({
      device,
      isGuestMode,
      lang,
      segmentAnonymousID,
      shop,
      token,
      url,
      userUID,
    })
  }

  static getCategoryUrl = async (categoryId) => {
    const categoryUrl = await getFlagValue('CategoryRedesign_20230613', false)
      .then((flag) =>
        flag ? `v7/categories/${categoryId}` : `v6/categories/${categoryId}`,
      )
      .catch(() => `v6/categories/${categoryId}`)

    return categoryUrl
  }

  static getQueryParams(options) {
    const filters = {
      size: [],
      color: [],
      categories: [],
      brand: [],
      price: [],
      sustainability: [],
    }

    const customKey = {
      brand: 'brands',
      sustainability: 'feature',
    }

    options.forEach(({ id, values }) => {
      let replaceId = id
      if (replaceId.includes(' ')) {
        replaceId = replaceId.replace(' ', '_')
      }

      if (values?.length) {
        filters[replaceId] = []
        filters[replaceId].push({ values })
      }
    })

    const queryParams = Object.keys(filters)?.reduce((result, key) => {
      const newResult = result
      const hasFilter = !!filters[key].length

      // for brand and sustainability need to customize key since query params, store and api wasn't sync
      const isCustomizeKey = ['brand', 'sustainability'].includes(key)

      if (hasFilter) {
        newResult[key] = Category.getFilterQueryString(
          filters[key],
          `filter[${isCustomizeKey ? customKey[key] : key}]`,
          key === 'price' ? '-' : ',',
        )
      }

      return newResult
    }, {})

    return Object.values(queryParams)
      .filter(Boolean)
      .map((value) => value)
      .join('&')
  }

  static getFilterQueryString(filter, param, joinWith = ',') {
    let query = ''
    const hasFilter = !!filter[0]?.values.length

    if (hasFilter) {
      const filterQuery = filter[0].values
        .map(encodeURIComponent)
        .join(joinWith)

      query = `${param}=${filterQuery}`
    }

    return query
  }

  static getCategoryRelated(categoryId) {
    return request({
      url: `v6/categories/${categoryId}/related`,
    })
  }
}

export default Category
