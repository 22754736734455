import Duck from 'extensible-duck'

const checkoutDuck = new Duck({
  namespace: 'checkout',
  store: 'googleMap',
  types: ['UPDATE_LAT_LONG'],
  initialState: {
    latitude: 13.7563,
    longitude: 100.5018,
    infos: {},
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.UPDATE_LAT_LONG:
        return {
          ...state,
          latitude: action.latitude,
          longitude: action.longitude,
          infos: action.infos,
        }
      default:
        return state
    }
  },
  creators: (duck) => ({
    updateLatitudeLongitude: (options) => ({
      type: duck.types.UPDATE_LAT_LONG,
      latitude: options.latitude,
      longitude: options.longitude,
      infos: options.infos,
    }),
  }),
})

export default checkoutDuck
