import { request } from './index'
import { HTTP_METHODS } from './const'

class Lookbook {
  static get(options) {
    const { id } = options
    const subUrl = Number.isNaN(+id) ? 'search?slug=' : ''
    const requestUrl = `v6/lookbooks/${subUrl}${id}`

    return request({
      url: requestUrl,
      method: HTTP_METHODS.get,
      shop: options.shopSlug,
      lang: options.languageSlug,
    })
      .then((res) => ({
        ...res,
        id,
        product_category_id: res.id_category || res.id_lookbook,
      }))
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }
}

export default Lookbook
