import { ICONS } from 'components/common/icon'

export const REFERRAL_HISTORY_TAB = {
  IN_PROGRESS: 'inprogress',
  SUCCESS: 'success',
  IN_COMPLETE: 'incomplete',
}

export const REFERRAL_ORDER_STATUS = {
  IN_PROGRESS: 'in-progress',
  SUCCESS: 'success',
  IN_COMPLETE: 'in-complete',
}

export const REFERRAL_HISTORY_LOCIZE = {
  REFERRAL_HISTORY_TITLE: {
    key: 'REFERRAL_HISTORY_TITLE',
    text: 'Referral History',
  },
  REFERRAL_HISTORY_SUB_TITLE: {
    key: 'REFERRAL_HISTORY_SUB_TITLE',
    text: 'Follow your friend progress and you will get cashback when your friends complete the order.',
  },
  REFERRAL_HISTORY_IN_PROGRESS_TAB: {
    key: 'REFERRAL_HISTORY_IN_PROGRESS_TAB',
    text: 'In-progress',
  },
  REFERRAL_HISTORY_SUCCESS_TAB: {
    key: 'REFERRAL_HISTORY_SUCCESS_TAB',
    text: 'Success',
  },
  REFERRAL_HISTORY_IN_COMPLETE_TAB: {
    key: 'REFERRAL_HISTORY_IN_COMPLETE_TAB',
    text: 'Incomplete',
  },
  REFERRAL_HISTORY_IN_PROGRESS_BAR_TEXT: {
    key: 'REFERRAL_HISTORY_IN_PROGRESS_BAR_TEXT',
    text: 'Used Code',
  },
  REFERRAL_HISTORY_SUCCESSFUL_ORDER: {
    key: 'REFERRAL_HISTORY_SUCCESSFUL_ORDER',
    text: 'Successful Order',
  },
  REFERRAL_HISTORY_SUCCESS_BAR_TEXT: {
    key: 'REFERRAL_HISTORY_SUCCESS_BAR_TEXT',
    text: 'Successful Ordered',
  },
  REFERRAL_HISTORY_IN_COMPLETE_BAR_TEXT: {
    key: 'REFERRAL_HISTORY_IN_COMPLETE_BAR_TEXT',
    text: 'Cashback Discarded',
  },
  REFERRAL_HISTORY_REWARD: {
    key: 'REFERRAL_HISTORY_REWARD',
    text: '100 ฿',
  },
  REFERRAL_HISTORY_IN_COMPLETE_INFO: {
    key: 'REFERRAL_HISTORY_IN_COMPLETE_INFO',
    text: 'Your cashback might have been discarded because it doesn’t meet the referral criteria.',
  },
  REFERRAL_HISTORY_IN_COMPLETE_LEARN_MORE: {
    key: 'REFERRAL_HISTORY_IN_COMPLETE_LEARN_MORE',
    text: 'Learn More',
  },
  REFERRAL_HISTORY_CASHBACK_REJECTED_TITLE: {
    key: 'REFERRAL_HISTORY_CASHBACK_REJECTED_TITLE',
    text: 'Why is my cashback rejected?',
  },
  REFERRAL_HISTORY_CASHBACK_REJECTED_INFO: {
    key: 'REFERRAL_HISTORY_CASHBACK_REJECTED_INFO',
    text: 'Your referral cashback was discarded can because this order was either cancelled, no-show for pickup, failed delivery, payment didn’t go through.',
  },
}

export const REFERRAL_EMPTY_INFO = {
  inprogress: {
    icon: ICONS.referralInprogessEmpty,
    titleKey: 'REFERRAL_HISTORY_IN_PROGRESS_EMPTY_TITLE',
    titleText: 'You don’t have any referrals in progress right now',
    subtitleKey: 'REFERRAL_HISTORY_IN_PROGRESS_EMPTY_SUB_TITLE',
    subtitleText: 'Invite your friends to track referrals in progress.',
  },
  success: {
    icon: ICONS.referralSuccessfulEmpty,
    titleKey: 'REFERRAL_HISTORY_SUCCESS_EMPTY_TITLE',
    titleText: 'You don’t have any successful referrals yet',
    subtitleKey: 'REFERRAL_HISTORY_SUCCESS_EMPTY_SUB_TITLE',
    subtitleText:
      'Once your friends have successfully used your code, you can view completed referrals here.',
  },
  incomplete: {
    icon: ICONS.referralIncompleteEmpty,
    titleKey: 'REFERRAL_HISTORY_IN_COMPLETE_EMPTY_TITLE',
    titleText: 'You don’t have any incomplete referrals',
    subtitleKey: 'REFERRAL_HISTORY_IN_COMPLETE_EMPTY_SUB_TITLE',
    subtitleText:
      'If your friends fail to use your code, you can view any incomplete referrals here.',
  },
}

export const REFERRAL_HISTORY_BAR_TEXT = {
  inprogress: {
    text: REFERRAL_HISTORY_LOCIZE.REFERRAL_HISTORY_IN_PROGRESS_BAR_TEXT,
    subText: REFERRAL_HISTORY_LOCIZE.REFERRAL_HISTORY_SUCCESSFUL_ORDER,
  },
  success: {
    text: REFERRAL_HISTORY_LOCIZE.REFERRAL_HISTORY_SUCCESS_BAR_TEXT,
  },
  incomplete: {
    text: REFERRAL_HISTORY_LOCIZE.REFERRAL_HISTORY_IN_PROGRESS_BAR_TEXT,
    subText: REFERRAL_HISTORY_LOCIZE.REFERRAL_HISTORY_IN_COMPLETE_BAR_TEXT,
  },
}

export const MINIMUM_SHOWN_ITEM = 5
